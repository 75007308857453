import React, { useState }  from "react";
import '../../styles/global.css';

const SplashScreen = ({siteSelection}) => {
    const [playingVideo, setPlayingVideo] = useState(true);
    const videoURL = 'https://ar-production-metalplus-frontend-origin.s3.ap-southeast-2.amazonaws.com/aa/HeavyMetalSite_SplashFootage.mp4';
    const imageURL = 'https://ar-production-metalplus-frontend-origin.s3.ap-southeast-2.amazonaws.com/aa/HeavyMetalSite_SplashFrameMockup.png';
    const videoEnded = () => {
        setPlayingVideo(false);
        localStorage.setItem("splashPresented", 'true');
        sessionStorage.setItem("splashPresented", 'true');
      }
  return (
    <div className="splash-top-container">
            {
              playingVideo ?
              <div style={{marginTop: '0px', width:"100%", maxHeight:"100vh"}}>
                  <video width="100%" height="auto" style={{maxHeight:"100vh"}} onEnded={videoEnded} playsInline autoPlay muted>
                        <source src={videoURL} type="video/mp4"/>
                    Your browser does not support the video tag.
                    </video>
                    {/*<!-- Preloading Image -->*/}
                    <img width="100%" height="auto" style={{maxHeight:"100vh", display:'none'}} src={imageURL}>
                    </img>
              </div>
              :
              <div style={{marginTop: '0px', width:"100%", maxHeight:"100vh"}}>
                <img width="100%" height="auto" style={{maxHeight:"100vh"}} src={imageURL}>
                </img>
                <div className="splash-image-btn-container">
                  <div className="splash-button-container">
                    <div onClick={()=>{siteSelection('Metal+')}}
                        className="splash-button">
                        Enter Metal+<br/>
                        <span className="small-text-font">
                          Web3 &amp; Blockchain enabled publishing
                        </span>
                    </div>
                  </div>
                  <div className="splash-btn-gap">&nbsp;</div>
                  <div className="splash-button-container">
                    <div onClick={()=>{siteSelection('Heavy_Metal')}}
                        className="splash-button">
                        Enter Heavy Metal<br/>
                        <span className="small-text-font">
                          Classic printed magazine, books &amp; merch
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
  );
};

export default SplashScreen;
