import * as React from "react";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import SplashScreen from '../components/organisms/SplashScreen';

const IndexPage = ({ pageContext }) => {
    const selectSiteToGo = (site) => {
        if(site == 'Metal+') {
            navigate("/metal")
            //window.location = `${window.location}/metal`;
        }else {
          window.location = 'https://shop.heavymetal.com/';
        }
    }
    return (
        <>
            <Helmet>
                <meta name="title"
                content={`METAL+ | The Bleeding Edge of Horror and Science Fiction`}
                />
                <meta
                name="description"
                content={`Its groundbreaking art would change the face of sci-fi, fantasy, and horror forever.`}
                />

                {/* OpenGraph tags */}
                <meta
                property="og:title"
                content={`METAL+ | The Bleeding Edge of Horror and Science Fiction`}
                />
                <meta
                property="og:description"
                content={`Its groundbreaking art would change the face of sci-fi, fantasy, and horror forever.`}
                />
                <meta property="og:type" content="website" />
                <meta
                property="og:image"
                content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
                />
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="50" />
                <title>{`The Bleeding Edge of Horror and Science Fiction NFT comics.`}</title>
            </Helmet>
            <h1 style={{display:'none'}}>
                The Bleeding Edge of Horror and Science Fiction NFT comics.
            </h1>
            <h2 style={{display:'none'}}>
                Its groundbreaking art would change the face of sci-fi, fantasy, and horror forever.
            </h2>
            <SplashScreen siteSelection={selectSiteToGo}></SplashScreen>
        </>
        
    );
}
export default IndexPage;
